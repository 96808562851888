<template>
  <div class="utility" style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  style="
                    padding-top: 0px !important;
                    padding-bottom: 0px !important;
                  "
                  >Prospects
                  <v-spacer></v-spacer>
                  <v-layout wrap>
                  <v-flex>
                    <v-card-text>
                       <v-layout wrap justify-center>
                        <v-flex xs6 sm3 md4 text-right pt-2 align-self-center
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm2 md2 align-self-center>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                             style="font-size: 12px;  ;height:30px"
                          ></v-select>
                        </v-flex>
                        <v-flex align-self-center
                          xs6
                          sm3
                          md3
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm1 md1 pt-2 text-right align-self-center>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm1 md1 pt-2 text-center align-self-center>
                         
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left align-self-center>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-flex>
                  </v-layout>
                  <v-sapcer></v-sapcer>
                  <v-col md="2">
                    <v-form
                      ref="filterform"
                      lazy-validation
                      @submit.prevent="getSearch"
                    >
                      <v-text-field
                        v-model="searchKey"
                        append-icon="mdi-magnify"
                        outlined
                        placeholder="Search"
                        dense
                        color="black"
                        single-line
                        hide-details
                        @click:append="getSearch"
                      ></v-text-field>
                      <v-btn
                        type="submit"
                        class="hidden-sm-and-down hidden-md-and-up"
                        >Submit</v-btn
                      >
                    </v-form>
                  </v-col>
                  <v-dialog v-model="filter" max-width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-on="on"
                        v-bind="attrs"
                        outlined
                        color="primary"
                        @click="fliter = true"
                        dense
                      >
                        Filtered Search
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="headline grey lighten-2">
                        Filtered Search
                      </v-card-title>

                      <v-card-text>
                        <form
                          ref="bthfilter"
                          lazy-validation
                          @submit.prevent="getSearch"
                          class="pt-4"
                        >
                          <v-text-field
                            outlined
                            dense
                            v-model="company"
                            label="Company"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            dense
                            v-model="address"
                            label="Address"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            dense
                            v-model="phone"
                            label="Phone"
                          ></v-text-field>
                          <v-text-field
                            outlined
                            dense
                            v-model="email"
                            label="Email"
                          ></v-text-field>

                          <v-text-field
                            outlined
                            dense
                            v-model="nature"
                            label="Nature"
                          ></v-text-field>
                          <v-btn
                            type="submit"
                            class="hidden-sm-and-down hidden-md-and-up"
                            >Submit</v-btn
                          >
                        </form>
                      </v-card-text>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="error" @click="closeFilter">
                          Clear
                        </v-btn>
                        <v-btn color="primary" class="mr-4" @click="getSearch">
                          Search
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-title>
                <v-progress-linear
                  :active="isLoading"
                  indeterminate
                  absolute
                  bottom
                  color="primary"
                ></v-progress-linear>
              </v-card>
              <v-card class="mb-4">
                <!-- <v-card-text
                  style="
                    padding: 0px !important;
                    padding-top: 10px !important;
                    height: 4em !important;
                  "
                >
                  <v-layout wrap style="align-items: center" justify-center>
                    <v-flex xs6 sm6 md6></v-flex>
                    <v-flex xs6 sm6 md6>
                      <v-layout wrap justify-center>
                        <v-flex xs6 sm6 md4 text-right pt-2
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm6 md2>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex
                          xs6
                          sm6
                          md3
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-right>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-center>
                         
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text> -->
                <v-data-table 
                  dense
                  :headers="headers"
                  :items="customers"
                  hide-default-footer
                  @click:row="rowClicked"
                  :items-per-page="itemsPerPage"
                  class="row-pointer tablewid pb-12 tableValue"
                >
                  <template v-slot:item.email="{ item }">
                    <span v-if="item.contactPerson">{{
                      item.contactPerson
                    }}</span
                    ><br />
                    <span style="color: darkblue" v-if="item.email">{{
                      item.email.slice(0, 37)
                    }}</span>
                  </template>
                  <template v-slot:item.nature="{ item }">
                    <span v-if="item.nature">
                      {{ item.nature }}
                    </span>
                  </template>
                  <template v-slot:item.address="{ item }">
                    <span v-if="item.address">
                      {{ item.address.slice(0, 16) }} </span
                    ><br />
                    <span v-if="item.city">{{ item.city }}</span>
                    <span v-if="item.zip"> , {{ item.zip }}</span>
                  </template>
                  <template v-slot:item.phone="{ item }">
                    <span style="color: darkblue" v-if="item.phone">{{
                      item.phone
                    }}</span>
                    <!-- <br />
                    <span style="color: darkblue" v-if="item.fax">{{
                      item.fax
                    }}</span> -->
                  </template>
                  <template v-slot:item.agentCode="{ item }">
                    <span
                      >{{ item.agentCode
                      }}<span v-if="item.updated_by"
                        >/{{ item.updated_by.agentCode }}</span
                      ></span
                    >
                    <!-- <br />
                    <span v-if="item.number">{{ item.number }}</span> -->
                  </template>
                  <template v-slot:item.nature="{ item }">
                    <span v-if="item.means">{{ item.means }}/</span>
                    <br />
                    <span v-if="item.nature">{{ item.nature }}</span>
                  </template>
                  <template v-slot:item.rating="{ item }">
                    <span>{{ item.statusValue }}/{{ item.rating }}</span>
                  </template>
                  <template v-slot:item.initialDate="{ item }">
                    <span v-if="item.initialDate">{{
                      ChangeFormateDate(item.initialDate)
                    }}</span
                    ><br />
                    <span v-if="item.lastDate">{{
                      ChangeFormateDate(item.lastDate)
                    }}</span>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-dialog v-model="item.sendMail" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          v-on="on"
                          v-bind="attrs"
                          @click="SendMail(item)"
                        >
                          mdi-email
                        </v-icon>
                      </template>

                      <v-card>
                        <v-card-title class="headline grey lighten-2">
                          ABB Labels
                        </v-card-title>

                        <v-card-text>
                          <form ref="mail" class="pt-2">
                            <v-text-field
                              v-model="recipient"
                              label="Recipient Email"
                              dense
                              outlined
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="subject"
                              label="Subject "
                              dense
                              outlined
                              required
                            ></v-text-field>
                            <!-- <v-textarea
                              outlined
                              dense
                              v-model="mssgData"
                              disabled
                              auto-grow
                            >
                            </v-textarea> -->
                            <vue-editor
                              v-model="mssgData"
                              ref="description"
                              disabled
                              color="#26af82"
                              :editorToolbar="customToolbar"
                            ></vue-editor>
                          </form>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn color="error" @click="item.sendMail = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            color="primary"
                            class="mr-4"
                            @click="Send(item)"
                          >
                            Send
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="item.deleteCustomer" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          v-on="on"
                          v-bind="attrs"
                          color="red"
                          @click="deleteConfirmation(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>

                      <v-card>
                        <v-card-title class="headline grey lighten-2">
                          Delete Confirmation
                        </v-card-title>

                        <v-card-text>
                          <span style="font-size: 16px; font-weight: 600"
                            >Please confirm that you want to Remove this Order
                            details ?
                          </span>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>

                          <v-btn
                            color="primary"
                            @click="item.deleteCustomer = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            color="error"
                            class="mr-4"
                            @click="Deletecustomer(item)"
                          >
                            Delete
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                  <template v-slot:top>
                    <!-- <v-data-footer ="{ pagination, options, updateOptions }"
                      :show-current-page="true"
                      disable-items-per-page
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    ></v-data-footer> -->
                  </template>
                </v-data-table>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 4%"
      right
      width="55"
    >
      <v-list nav dense>
        <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <vue-excel-xlsx
              :data="customers"
              :columns="columns"
              :filename="'Customers List'"
              ><v-icon color="primary">mdi-file-excel</v-icon></vue-excel-xlsx
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <a
              v-if="searchKey || company || address || phone || nature || email"
              :href="
                'http://13.127.171.21/pdf/generate/Prospects_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page +
                '&prospectType=search' +
                '&keyword=' +
                searchKey +
                '&companyName=' +
                company +
                '&address=' +
                address +
                '&phone=' +
                phone +
                '&email=' +
                email +
                '&nature=' +
                nature
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
            <a
              v-else
              :href="
                'http://13.127.171.21/pdf/generate/Prospects_Report/?limit=' +
                itemsPerPage +
                '&page=' +
                page
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>

        <v-list-item link to="/addProspect" title="Add">
          <v-list-item-icon>
            <v-icon color="primary">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      sendMail: false,
      deleteCustomer: false,
      headers: [
        {
          text: "Company",
          align: "start",
          width: "7%",
          value: "companyName",
        },
        {
          text: "Conatct/Email",
          value: "email",
          align: "start",
          sortable: false,
          width: "1%",
        },
        {
          text: "Address",
          value: "address",
          align: "start",
          width: "15%",
          sortable: false,
        },
        {
          text: "Phone/Fax",
          value: "phone",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "I/L Date",
          value: "initialDate",
          align: "start",
          width: "6%",
          sortable: false,
        },
        {
          text: "Means/Nature",
          value: "nature",
          align: "start",
          width: "10%",
          sortable: false,
        },
        {
          text: "Agent",
          value: "agentCode",
          align: "start",
          width: "2%",
          sortable: false,
        },

        {
          text: "S/R",
          value: "rating",
          align: "start",
          width: "2%",
          sortable: false,
        },
        {
          text: "Actions",
          value: "actions",
          align: "start",
          sortable: false,
          width: "4%",
        },
      ],
      columns: [
        {
          label: "Company",
          field: "companyName",
        },
        {
          label: "Contact/Email",
          field: "email",
        },
        {
          label: "Address",
          field: "address",
        },
        {
          label: "Phone/Fax",
          field: "phone",
        },
      ],
      filter: false,
      recipient: null,
      sendTo: [],
      subject: null,
      searchKey: null,
      keyword: null,
      company: null,
      address: null,
      phone: null,
      email: null,
      nature: null,
      customerID: null,
      mini: true,
      customers: [],
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      itemsPerPage: 20,
      page: 1,
      totalpages: 0,
      ItemsCount: [20, 30, 50, 100],
      appLoading: false,
      mssgData: null,
      isLoading: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      customToolbar: [[]],
    };
  },
  beforeMount() {
    this.getCustomers();
  },
  watch: {
    // searchKey: function () {
    //   this.getSearch();
    // },
    // company: function () {
    //   this.getSearch();
    // },
    // address: function () {
    //   this.getSearch();
    // },
    phone: function () {
      var str = this.phone;
      var ind;
      if (str.length == 3) {
        ind = str.substring(0, 3);
        this.phone = ind + "-";
      }
      if (str.length == 8) {
        ind = str.substring(0, 7);
        this.phone = ind + "-";
      }
    },
    // email: function () {
    //   this.getSearch();
    // },
    // nature: function () {
    //   this.getSearch();
    // },
    // customerID: function () {
    //   this.getSearch();
    // },
    itemsPerPage: function () {
      if (
        this.searchKey ||
        this.company ||
        this.address ||
        this.phone ||
        this.email ||
        this.nature ||
        this.customerID
      ) {
        this.getSearch();
      } else this.getCustomers();
    },
    // page: function () {
    //   if (this.page > 0) this.getCustomers();
    // },
  },
  methods: {
    prevpage() {
      if (this.page > 1) {
        if (
          this.searchKey ||
          this.company ||
          this.address ||
          this.phone ||
          this.email ||
          this.nature ||
          this.customerID
        ) {
          this.page--;
          this.getSearch();
        } else {
          this.page--;
          this.getCustomers();
        }
      }
    },
    nextpage() {
      if (this.page < this.totalpages) {
        if (
          this.searchKey ||
          this.company ||
          this.address ||
          this.phone ||
          this.email ||
          this.nature ||
          this.customerID
        ) {
          this.page++;
          this.getSearch();
        } else {
          this.page++;
          this.getCustomers();
        }
      }
    },
    getPdf() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/pdf/generate/Customers_Report",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // status:"active",
          limit: this.itemsPerPage,
          page: this.page,
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log(response.data.status);
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },

    getCustomers() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/prospects/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          limit: this.itemsPerPage,
          page: this.page,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.customers = response.data.data;
          this.count = response.data.totalLength;
          this.totalpages = Math.ceil(
            response.data.totalLength / this.itemsPerPage
          );

          if (response.data.length > 0) {
            this.pageStart = (response.data.page - 1) * response.data.limit + 1;
            this.pageEnd = this.pageStart + response.data.length - 1;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },

    getSearch() {
      if (this.keyword == null) {
        this.page = 1;
        this.keyword = this.searchKey;
      }
      if (this.keyword != this.searchKey) {
        this.page = 1;
        this.keyword = this.searchKey;
      }
      this.filter = false;
      this.isLoading = true;
      var data = {};
      data.limit = this.itemsPerPage;
      data.page = this.page;
      if (this.searchKey != "") data.keyword = this.searchKey;
      else {
        this.getCustomers();
        this.isLoading = false;
      }
      data.companyName = this.company;
      data.address = this.address;
      data.phone = this.phone;
      data.email = this.email;
      data.nature = this.nature;
      // data.customerID = this.customerID;
      axios({
        method: "POST",
        url: "/prospect/search",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          if (response.data.status) {
            this.isLoading = false;

            this.customers = response.data.data;
            this.count = response.data.totalLength;
            this.totalpages = Math.ceil(
              response.data.totalLength / this.itemsPerPage
            );

            if (response.data.length > 0) {
              this.pageStart =
                (response.data.page - 1) * response.data.limit + 1;
              this.pageEnd = this.pageStart + response.data.length - 1;
            }

            this.company = null;
            this.phone = null;
            this.email = null;
            this.address = null;
            this.nature = null;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    SendMail(item) {
      this.sendMail = true;
      this.mssgData =
        "<p>Company:" +
        item.companyName +
        "</p>" +
        "<p>Contact:" +
        item.contactPerson +
        "</p>" +
        "<p>Email:" +
        item.email +
        "</p>" +
        "<p>Phone:" +
        item.phone +
        "</p>" +
        "<p>fax:" +
        item.fax +
        "</p>" +
        "<p>Address:" +
        item.address +
        "</p>" +
        "<p>Means:" +
        item.means +
        "</p>" +
        "<p>Nature:" +
        item.nature +
        "</p>";
    },
    Send(item) {
      item.sendMail = false;

      //  var message = this.mssgData.replace("↵", "\n");
      this.sendTo.push(this.recipient);
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: "customer",
          id: item._id,
          emailTo: this.sendTo,
          subject: this.subject,
          message: this.mssgData,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.showsnackbar = true;
          this.msg = "Prospect details successfully sent!";
          console.log(response.data.msg);
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    deleteConfirmation() {
      this.deleteCustomer = true;
    },
    Deletecustomer(item) {
      item.deleteCustomer = false;
      axios({
        method: "POST",
        url: "/prospect/delete/" + item._id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Prospect deleted successfully!";
            this.getCustomers();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    closeFilter() {
      this.filter = false;
      this.company = "";
      this.phone = "";
      this.email = "";
      this.address = "";
      this.nature = "";
      this.getCustomers();
    },
    rowClicked(item) {
      this.$router.push("/Prospect/" + item._id);
    },
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
  },
};
</script>
<style >
.tablewid.wid {
  width: 150px !important;
}
.v-card__title {
  line-height: 1rem !important;
}

.class-on-data-table table {
  table-layout: fixed;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
a {
  text-decoration: none !important;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
/* .v-data-table td {
  font-size: 0.7em;
} */
</style>


