<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="primary" :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title v-if="invoiceData"
                  >Invoice : {{ invoiceData.invoiceID }}
                  <v-spacer></v-spacer>
                  <v-btn icon color="info" @click="prevPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon color="info" @click="nextPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Billing Address</span>
                  </v-row>
                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Bill To Company"
                        outlined
                        dense
                        v-model="invoice.billToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Address"
                        outlined
                        dense
                        v-model="invoice.billToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="invoice.billToCity"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        ref="state"
                        color="black"
                        disabled
                        label="State"
                        outlined
                        dense
                        v-model="invoice.billToStateId"
                        :items="states"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Zip"
                        outlined
                        dense
                        v-model="invoice.billToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px">Shipping Address</span>
                  </v-row>
                  <v-row dense>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Ship To Company"
                        outlined
                        dense
                        v-model="invoice.shipToCompany"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Address"
                        disabled
                        outlined
                        dense
                        v-model="invoice.shipToAddress"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="City"
                        outlined
                        disabled
                        dense
                        v-model="invoice.shipToCity"
                      ></v-text-field>
                    </v-col>

                    <v-col md="1" style="padding-bottom: 0px !important">
                      <v-select
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        disabled
                        outlined
                        dense
                        v-model="invoice.shipToStateId"
                        item-text="alphaCode"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Zip"
                        disabled
                        outlined
                        dense
                        v-model="invoice.shipToZip"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="invoice.invoiceDate"
                            label="Invoice Date"
                            disabled
                            color="black"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.invoiceDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="3"
                      md="3"
                      style="padding-bottom: 0px !important"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="invoice.shippingDate"
                            disabled
                            label="Shipping Date"
                            outlined
                            color="black"
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="invoice.shippingDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Customer P.O"
                        disabled
                        outlined
                        dense
                        v-model="invoice.customerPO"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="ABB Order No."
                        disabled
                        outlined
                        dense
                        v-model="invoice.orderId"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        label="Ship Via"
                        disabled
                        v-model="invoice.shipMethodId"
                        item-text="name"
                        item-value="_id"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        ref="terms"
                        color="black"
                        label="Terms"
                        disabled
                        outlined
                        hide-details
                        v-model="invoice.terms"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col md="2" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Agent"
                        outlined
                        dense
                        v-model="invoice.agentCode"
                      ></v-text-field>
                    </v-col>

                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        label="Phone"
                        outlined
                        dense
                        v-model="invoice.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3" style="padding-bottom: 0px !important">
                      <v-text-field
                        color="black"
                        disabled
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <!-- <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        color="black"
                        label="Phone"
                        outlined
                        dense
                        v-model="invoice.phone"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        color="black"
                        label="Email"
                        outlined
                        dense
                        v-model="invoice.email"
                      ></v-text-field>
                    </v-col>
                  </v-row> -->
                </v-card-title>
              </v-card>
              <v-card class="mb-4">
                <v-card-title class="titlestyle">
                  <v-row dense style="width: 100% !important">
                    <v-col md="12" class="text-left"
                      ><p class="pt-4">Invoices</p></v-col
                    >
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="productinfo"
                  hide-default-footer
                >
                  <template v-slot:item.isTaxable="{ item }">
                    <v-simple-checkbox
                      v-model="item.isTaxable"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                </v-data-table>

                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Subtotal"
                        outlined
                        dense
                        disabled
                        v-model="estTotal"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Sale Tax"
                        outlined
                        disabled
                        dense
                        v-model="salesTax"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Freight"
                        outlined
                        dense
                        disabled
                        v-on:input="balanceComputation()"
                        v-model="freight"
                      ></v-text-field>
                    </v-col>
                    <v-col md="1">
                      <v-text-field
                        color="black"
                        label="Discount"
                        outlined
                        disabled
                        dense
                        v-on:input="balanceComputation()"
                        v-model="discount"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        v-if="userRole == 'admin'"
                        color="black"
                        label="Payment"
                        outlined
                        disabled
                        dense
                        v-on:input="balanceComputation()"
                        v-model="payment"
                      ></v-text-field>
                      <v-text-field
                        v-else
                        color="black"
                        label="Payment"
                        outlined
                        dense
                        disabled
                        v-model="payment"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Total"
                        outlined
                        dense
                        disabled
                        v-model="amttotal"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        color="black"
                        label="Balance"
                        outlined
                        dense
                        disabled
                        v-model="balance"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 5%"
      right
      width="55"
    >
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon color="primary">mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <a
              target="_blank"
              :href="
                'http://13.233.107.20/pdf/generate/Invoice_Details/?invoiceId=' +
                $route.params.id
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="emailDialog = true"
              >mdi-gmail</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="emailDialog" max-width="800">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                ABB Labels
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="sendfrom"
                    label="Send From"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sendto"
                    label="Send To"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <vue-editor
                    v-model="message"
                    placeholder="Message"
                    color="#26af82"
                    :editorToolbar="customToolbar"
                  ></vue-editor>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="emailDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="sendMail">
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item
          link
          :to="'/editInvoice/' + $route.params.id"
          title="Edit Invoice"
        >
          <v-list-item-icon>
            <v-icon color="primary">mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Attachment">
          <v-list-item-icon v-if="files.length > 0">
            <a
              target="_blank"
              :href="'http://13.233.107.20/file/get/' + files[0]"
              class="downlink"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </a>
          </v-list-item-icon>
          <v-list-item-icon v-else>
            <v-icon color="grey" class="mr-2"> mdi-eye-off </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link to="/addInvoice/" title="Add Invoice">
          <v-list-item-icon>
            <v-icon color="primary">mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  data() {
    return {
      emailDialog: false,
      sendto: null,
      sendfrom: null,
      message: null,
      subject: null,
      invoice: {
        billToCompany: null,
        billToAddress: null,
        billToStateId: null,
        billToCity: null,
        billToZip: null,
        shipToCompany: null,
        shipToAddress: null,
        shipToStateId: null,
        shipToCity: null,
        shipToZip: null,
        terms: null,
        email: null,
        phone: null,
        customerPO: null,
        customerId: null,
        shipMethodId: null,
        orderId: null,
        invoiceDate: new Date().toISOString().substr(0, 10),
        shippingDate: "",
        invoiceProducts: [],
        deleteProducts: [],
        freight: "",
        discount: "",
        payment: "",
      },
      deleteProducts: [],
      files: [],
      invoiceData: null,
      states: [],
      terms: [],
      shipvia: [],
      menu1: false,
      modal: false,
      menu2: false,
      dialog: false,
      dialogDelete: false,
      estTotal: 0,
      salesTax: 0,
      freight: 0,
      discount: 0,
      payment: 0,
      amttotal: 0,
      balance: 0,
      sn_value: "",
      customToolbar: [[]],
      productinfo: [
        {
          quantity: "",
          shipQuantity: "",
          description: "",
          unitPrice: "",
          um: "",
          amount: "",
          isTaxable: false,
        },
      ],
      headers: [
        {
          text: "Order Qty",
          align: "start",
          value: "quantity",
          sortable: false,
        },
        {
          text: "Ship Qty",
          align: "start",
          value: "shipQuantity",
          sortable: false,
        },
        { text: "Description", value: "description", sortable: false },

        { text: "Unit price", value: "unitPrice", sortable: false },

        { text: "Um", value: "um", sortable: false },
        { text: "Tax", value: "isTaxable", sortable: false },
        { text: "Amount", value: "amount", sortable: false },
        // { text: "Amt", value: "totalAmount" },
        // { text: "Bal", value: "balance" },
        // { text: "Agent", value: "agentCode" },
        // { text: "Actions", value: "actions", sortable: false },
      ],
      updateProducts: [],
      desserts: [],
      editedIndex: -1,
      quantity: "",
      shipQuantity: 0,
      description: "",
      unitPrice: "",
      um: "",
      amount: "",
      isTaxable: false,
      qtyrules: [
        (v) => !!v || "Required",
        (v) => v >= 0 || "Number is Expected",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
      ],
      shipqtyrules: [
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],
      untPrice: [
        (v) => !!v || "Required",
        (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],
      descriptionrule: [(v) => !!v || "required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      agentCode: localStorage.getItem("agentCode"),
      showsnackbar: false,
      msg: "",
      timeout: 2000,
      userRole: localStorage.getItem("role"),
    };
  },

  beforeMount() {
    this.stateList();
    this.termList();
    this.shipList();
  },

  created() {
    this.getInvoice();
    // this.getTax();
  },

  methods: {
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            if (response.data.data.email)
              this.sendfrom = response.data.data.email;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    sendMail() {
      this.emailDialog = false;

      this.sendTo = this.sendto.split(",");
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: "invoice",
          id: this.$route.params.id,
          emailTo: this.sendTo,
          emailFrom: this.sendfrom,
          subject: this.subject,
          message: this.message,
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log(response.data.msg);
          this.message = "";
          this.msg = "Email send Successfully!";
          this.showsnackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    nextPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/invoice/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          invoiceid: this.$route.params.id,
          type: "previous",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Invoice/" + response.data.data._id);
            this.getInvoice();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    prevPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/invoice/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          invoiceid: this.$route.params.id,
          type: "next",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Invoice/" + response.data.data._id);
            this.getInvoice();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getTax() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/tax/view/" + "5f7e94279eeed32c3477263d",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.taxAmount = response.data.data.taxAmount;
            this.initialComputation();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getInvoice() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/invoice/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.invoiceData = response.data.data;
            this.invoice.orderId = response.data.data.orderId.orderID;
            this.invoice.billToCompany = response.data.data.billToCompany;
            this.invoice.billToAddress = response.data.data.billToAddress;
            this.invoice.billToCity = response.data.data.billToCity;
            if (response.data.data.billToStateId)
              this.invoice.billToStateId = response.data.data.billToStateId._id;
            this.invoice.billToZip = response.data.data.billToZip;
            this.invoice.shipToCompany = response.data.data.shipToCompany;
            this.invoice.shipToAddress = response.data.data.shipToAddress;
            this.invoice.shipToCity = response.data.data.shipToCity;
            if (response.data.data.shipToStateId)
              this.invoice.shipToStateId = response.data.data.shipToStateId._id;
            this.invoice.shipToZip = response.data.data.shipToZip;
            this.invoice.customerPO = response.data.data.customerPO;
            this.invoice.terms = response.data.data.terms;
            if (response.data.data.shipMethodId)
              this.invoice.shipMethodId = response.data.data.shipMethodId._id;

            if (response.data.data.notes) this.notes = response.data.data.notes;
           if(response.data.data.customerId || response.data.data.updated_by)
            this.invoice.agentCode = response.data.data.customerId.agentCode +"/"+response.data.data.updated_by.agentCode
            this.invoice.phone = response.data.data.phone;
            this.invoice.email = response.data.data.email;
            this.discount = response.data.data.discount;
            this.freight = response.data.data.freight;
            this.payment = response.data.data.payment;
            this.productinfo = this.invoiceData.products;
            var estimate = 0;
            for (var j = 0; j < this.productinfo.length; j++) {
              estimate = estimate + this.productinfo[j].amount;
            }
            if(response.data.data.orderId)
            this.files = response.data.data.orderId.attachments;
            this.estTotal = estimate.toFixed(2);
            this.amttotal = response.data.data.totalAmount;
            this.salesTax = response.data.data.tax;
            // this.estTotal = this.amttotal - this.salesTax;

            this.balance = response.data.data.balance;

            this.invoice.invoiceDate = this.ChangeFormateDate(
              this.invoiceData.invoiceDate
            );
            this.invoice.shippingDate = this.ChangeFormateDate(
              this.invoiceData.shippingDate
            );
            this.subject = "ABB Label - Invoice #" + this.invoiceData.invoiceID;
            this.sendto = this.invoice.email;
            this.getProfile();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    termList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/term/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.terms = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    shipList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/shippingMethod/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.shipvia = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    update() {
      this.sn_value = this.sn_value + 1;
      this.productinfo.push({
        quantity: "",
        shipQuantity: "",
        description: "",
        unitPrice: "",
        um: "",
        amount: "",
        isTaxable: false,
        sr_no: this.sn_value,
      });
    },
    removee(index, item) {
      this.productinfo.splice(index, 1);
      for (var r = 0; r < this.updateProducts.length; r++) {
        if (this.updateProducts[r].sr_no == item.sr_no) {
          this.updateProducts.splice(r, 1);
        }
      }
      if (item._id) this.deleteProducts.push(item._id);
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);
      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);
      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    initialComputation() {
      var estimate = 0;
      var tax = 0;
      // var frght = this.freight;
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.amttotal = (estimate + tax).toFixed(2);
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);
      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    balanceComputation() {
      // console.log("here")
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);

      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);

      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    amtComputation(item) {
      var estimate = 0;
      var tax = 0;
      var frght = this.freight;
      if (item.shipQuantity) {
        item.amount = item.unitPrice * item.shipQuantity;
      } else item.amount = item.unitPrice * item.quantity;

      for (var j = 0; j < this.productinfo.length; j++) {
        estimate = estimate + this.productinfo[j].amount;
        if (this.productinfo[j].isTaxable)
          tax = tax + this.productinfo[j].amount * (this.taxAmount / 100);
      }
      this.amttotal = (estimate + tax + (frght - this.discount)).toFixed(2);
      this.estTotal = estimate.toFixed(2);
      this.salesTax = tax.toFixed(2);
      this.balance = (this.amttotal - this.payment).toFixed(2);
    },

    prdUpdate(item) {
      if (item._id) {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var i = 0; i < this.updateProducts.length; i++) {
            if (this.updateProducts[i]._id == item._id) {
              this.updateProducts.splice(i, 1);
            }
          }
          this.updateProducts.push(item);
        }
      } else {
        if (this.updateProducts.length == 0) this.updateProducts.push(item);
        else {
          for (var k = 0; k < this.updateProducts.length; k++) {
            if (this.updateProducts[k].sr_no == item.sr_no) {
              this.updateProducts.splice(k, 1);
            }
          }
          this.updateProducts.push(item);
        }
      }
    },
    addValidate() {
      if (this.$refs.form.validate()) this.editInvoice();
    },

    editInvoice() {
      this.appLoading = true;
      this.invoice.invoiceProducts = this.updateProducts;
      this.invoice.deleteProducts = this.deleteProducts;
      this.invoice.freight = this.freight;
      this.invoice.payment = this.payment;
      this.invoice.discount = this.discount;
      axios({
        method: "POST",
        url: "/invoice/edit/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.invoice,
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
            this.getInvoice();
            this.msg = "Invoice Edited!";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
        });
    },
    formreset() {
      this.$refs.form.reset();
    },

    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black !important;
}
.v-text-field input {
  color: grey !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
a {
  text-decoration: none !important;
}
</style>



