import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        Sidebar_drawer: null,
        Customizer_drawer: false,
        SidebarColor: 'white',
        SidebarBg: '',
        isLoggedIn: !!localStorage.getItem('token'),
      },
    mutations: {
        SET_SIDEBAR_DRAWER (state, payload) {
            state.Sidebar_drawer = payload
        },
        SET_CUSTOMIZER_DRAWER (state, payload) {
            state.Customizer_drawer = payload
        },
        SET_SIDEBAR_COLOR (state, payload) {
            state.SidebarColor = payload
        },
        loginUser(state) {
            state.isLoggedIn = true
            state.isLog = true
          },
      
          logoutUser(state) {
            state.isLoggedIn = false
            state.isLog = false
          }
    },
    actions: {

    }
})