<template>
  <div style="padding-right: 3.4em">
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form"  lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
        <v-card class="mb-1">
          <v-card-title > Users 
          <v-layout wrap>
            <v-flex>
              <v-card-text>
                <v-layout wrap justify-center>
                       
                        <v-flex xs6 sm6 md4 text-right pt-2 align-self-center
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm6 md2 align-self-center>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                             style="font-size: 12px; background-color:red ;height:30px"
                          ></v-select>
                        </v-flex>
                        <v-flex align-self-center
                          xs6
                          sm6
                          md2
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-right align-self-center>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-center align-self-center>
                          
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left align-self-center>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
              </v-card-text>
            </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
            
             </v-card-title >
         
        </v-card>
         <v-card class="mb-4">
                <!-- <v-card-text style="padding: 0px !important;padding-top:10px!important;height:4em !important;">
                  <v-layout wrap style="align-items: center" justify-center>
                    <v-flex xs6 sm6 md6></v-flex>
                    <v-flex xs6 sm6 md6>
                      <v-layout wrap justify-center>
                       
                        <v-flex xs6 sm6 md4 text-right pt-2
                          >Items Per Page</v-flex
                        >
                        <v-flex xs6 sm6 md2>
                          <v-select
                            solo
                            flat
                            v-model="itemsPerPage"
                            :items="ItemsCount"
                            dense
                            required
                          ></v-select>
                        </v-flex>
                        <v-flex
                          xs6
                          sm6
                          md2
                          text-center
                          pt-2
                          style="color: black"
                        >
                          {{ pageStart }}-{{ pageEnd }} of {{ count }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-right>
                          <span v-if="page > 1">
                            <v-icon @click="prevpage" color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-left</v-icon
                            >
                          </span>
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-center>
                          
                          {{ page }}
                        </v-flex>
                        <v-flex xs2 sm2 md1 pt-2 text-left>
                          <span v-if="page < totalpages">
                            <v-icon @click="nextpage" color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                          <span v-else>
                            <v-icon disabled color="primary"
                              >mdi-chevron-right</v-icon
                            >
                          </span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card-text> -->
                <v-data-table
                  bordered
                  :headers="headers"
                  :items="customers"
                  hide-default-footer
                  dense
                  :items-per-page="itemsPerPage"
                  class="row-pointer tablewid pb-12 tableValue"
                >
                  <!-- @click:row="rowClicked" -->
                </v-data-table>
              </v-card>
       </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer style="position: fixed; padding-top: 5%" right width="55">
      <v-list nav dense>
        <!-- <v-list-item link to="" title="Excel">
          <v-list-item-icon>
            <v-icon>mdi-file-excel</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
        <v-list-item link title="Pdf">
          <v-list-item-icon>
            <v-icon>mdi-file-pdf</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item> -->
        <v-list-item link  to="/addEmployee" title="Add Employee">
          <v-list-item-icon>
            <v-icon color="primary">mdi-account-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from 'axios'
// import pdf from 'vue-pdf'
export default {
  // components: {
  //   pdf
  // },
  data() {
    return {
      headers: [
        { text: "Agent Code",align: "start", value: "agentCode",},
        { text: "First Name", value: "firstName", sortable: true,},
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
       { text: "Role", value: "role" },
        
        
      ],
    
      mini: true,
      customers:[],
      pageStart: 0,
      pageEnd: 0,
      count: 0,
      itemsPerPage: 20,
      page: 1,
      totalpages: 0,
      ItemsCount: [20, 30, 50, 100],
    };
  },
  beforeMount() {
    this.getCustomers();
  },
  watch:{
    itemsPerPage: function () {
     
        this.getCustomers();
     
    },
  },
  methods:{
    prevpage() {
      if (this.page > 1) {
      // if (this.searchKey || this.company || this.address || this.phone || this.email || this.nature || this. customerID) {
          this.page--;
          this.getCustomers();
        // } else {
        //   this.page--;
        //   this.getCustomers();
        // }
      }
    },
    nextpage() {
      if (this.page < this.totalpages) {
      // if (this.searchKey || this.company || this.address || this.phone || this.email || this.nature || this. customerID) {
          this.page++;
          this.getCustomers();
        // } else {
        //   this.page++;
        //   this.getCustomers();
        // }
      }
    },
     getCustomers() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/users/list",
        headers: {
          token: localStorage.getItem("token")
        },
        data:{
        //   role:'admin'
          limit: this.itemsPerPage,
          page: this.page,
        },
        // params: {
        //   // status:"active",
        //    limit: this.itemsPerPage,
        //   page: this.page,
        // }
      })
        .then(response => {
          this.appLoading = false;
          this.customers = response.data.data;
          this.count = response.data.totalLength;
          this.totalpages = Math.ceil(
            response.data.totalLength / this.itemsPerPage
          );

          if (response.data.length > 0) {
            this.pageStart = (response.data.page - 1) * response.data.limit + 1;
            this.pageEnd = this.pageStart + response.data.length - 1;
          }
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
   
  }
};
</script>
<style >
tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
.row-pointer>.v-data-table__wrapper>table>tbody>tr :hover {  
cursor: pointer;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
/* .v-data-table td {
  font-size: .7em;
} */
</style>



