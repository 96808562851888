<template>
  <div style="padding-right: 0%">
    <v-snackbar v-model="showsnackbar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="empform" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title> Add Employee </v-card-title>
              </v-card>
              <v-card class="mb-1">
                <v-card-title>
                  <v-row dense>
                    <v-col cols="12" sm="6" class="pr-1">
                      <v-text-field
                        ref="firstname"
                        color="black"
                        label="First Name"
                        outlined
                        dense
                        :rules="firstName"
                        v-model="employee.firstName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-1">
                      <v-text-field
                        ref="lastName"
                        color="black"
                        label="Last Name"
                        outlined
                        dense
                        :rules="lastName"
                        v-model="employee.lastName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-1">
                      <v-text-field
                        ref="Agentcode"
                        color="black"
                        label="Agent code"
                        outlined
                        dense
                        :rules="agentCode"
                        v-model="employee.agentCode"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pl-1">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email"
                        outlined
                        dense
                        :rules="email"
                        v-model="employee.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="12">
                      <v-row >
                      <v-col md="12" style="padding-top: 17px;">
                            <img :src="url" class="role active" style="width:100px;height:100px;" />
                          </v-col>
                      </v-row>
                      <v-file-input
                        v-model="atfiles"
                        @change="getFiles"
                        color="black"
                        counter
                        ref="files"
                        dense
                        label="File input"
                        
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <!-- <template v-slot:selection="{ index, text }">
                          <v-chip v-if="index < 2" dark label small>
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template> -->
                      </v-file-input>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" lg="12">
                    <label>Description</label>
                    <vue-editor
                      v-model="description"
                      ref="description"
                      placeholder="Message"
                      label="Description"
                      color="#26af82"
                      :editorToolbar="customToolbar"
                    ></vue-editor>
                  </v-col> -->
                    <v-row v-if="errmsg" justify="center" style="width:100%!important">
                      <span style="color:red">{{errmsg}}</span>
                      
                    </v-row>
                    <v-row justify="center"  style="width:100%!important">
                      
                      <v-btn
                        class="mx-2"
                        dark
                        color="primary"
                        @click="inputCheck"
                      >
                        SUBMIT
                      </v-btn>
                    </v-row>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
// import { VueEditor } from "vue2-editor";
export default {
  // components: {
  
  //   VueEditor
  // },
  data() {
    return {
      employee: {
        firstName: null,
        lastName: null,
        email: null,
        agentCode: null,
      },
      empID:null,
      url:null,
      atfiles:null,
      attachments:[],
      attachmentsformData: new FormData(),
      firstName: [(v) => !!v || " Required"],
      lastName: [(v) => !!v || " Required"],

      agentCode: [(v) => !!v || " Required"],
      email: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid",
      ],
      customToolbar:[["bold", "italic", "underline"]],
      showsnackbar: false,
      msg: "",
      errmsg:"",
      timeout: 2000,
    };
  },
  methods: {
    inputCheck() {
      this.msg = null;

      if (this.$refs.empform.validate()) {
        this.addEmployee();
      } else {
        this.msg = "Please fill the details  to Submit! ";
        return;
      }
    },
    addEmployee() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/employee/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: this.employee,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.employee.firstName = null;
            this.employee.lastName = null;
            this.employee.email = null;
            this.employeeagentCode = null;
            this.empID = response.data.Oid;
            if (this.attachments.length > 0) {
              for (var i = 0; i < this.attachments.length; i++) {
                this.attachmentsformData.append(
                  "photo",
                  this.attachments[i]
                );
              }
              // this.uploadattachments();  
            } else{
           this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Employee Added!",
            });
             this.$router.go(-1);
            }
          } else {
            this.errmsg = response.data.msg;
          }
        })
        .catch((err) => {
          var msg = err;
          console.log(msg);
          this.appLoading = false;
          this.ServerError = true;
        });
    },
    getFiles(){
      var selectedFiles = this.atfiles;
      // console.log(selectedFiles)
      this.url = URL.createObjectURL(selectedFiles);
      if (this.atfiles) {
        this.msg = null;
        this.attachments.push(selectedFiles);
      }
    },
     uploadattachments() {
      axios({
        method: "POST",
        url: "/user/upload/photo/" + this.empID,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },

        data: this.attachmentsformData,
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          //this.appLoading = true;
          // vm.uploadMsg = "Uploading " + percentCompleted + "%";
          if (percentCompleted == 100) {
            // this.appLoading = false;
          }
        },
      })
        .then((response) => {
          if (response.status) {
            this.appLoading = false;
             this.$notify({
              group: "appNotify",
              title: "ABB Labels",
              text: "Employee Added!",
            });
             this.$router.go(-1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style >

.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: black !important;
}
.v-text-field input {
  color: black !important;
}
</style>
