<template>
  <div style="padding-right: 3.4em">
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <!-- <v-snackbar v-model="showsnackbar" color="black" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{msg}}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color:white;">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar> -->

    <v-layout wrap justify-center>
      <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12 pt-2>
              <v-card class="mb-1">
                <v-card-title
                  >Prospect
                  <v-spacer></v-spacer>
                   

                  <v-btn icon color="info" @click="prevPage()">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn icon color="info" @click="nextPage()">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <!-- <v-col md="1">
                      <v-text-field
                        color="black"
                        label="ID"
                        outlined
                        dense
                        disabled
                        v-model="customer.customerID"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col md="1">
                  <v-text-field
                    color="black"
                    outlined
                    label="Plate No."
                    dense
                    disabled
                    v-model="customer.number"
                  ></v-text-field></v-col> -->
                    <v-col cols="12" sm="3" md="3">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Name of the Company"
                        :rules="companyRules"
                        disabled
                        outlined
                        dense
                        v-model="customer.companyName"
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        ref="contactPerson"
                        color="black"
                        label="Contact Person"
                        outlined
                        disabled
                        dense
                        v-model="customer.contactPerson"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        ref="email"
                        color="black"
                        label="Email ID"
                        :rules="emailRules"
                        outlined
                        disabled
                        dense
                        v-model="customer.email"
                      ></v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        disabled
                        ref="phone"
                        color="black"
                        label="Phone Number"
                        outlined
                        :rules="phoneRules"
                        dense
                        v-model="customer.phone"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col md="2">
                      <v-text-field
                        disabled
                        ref="fax"
                        color="black"
                        label="Fax"
                        outlined
                        dense
                        v-model="customer.fax"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 13px"> Address</span>
                  </v-row>
                  <v-row dense>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="Address"
                        outlined
                        dense
                        v-model="customer.address"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="City"
                        outlined
                        dense
                        v-model="customer.city"
                      ></v-text-field>
                    </v-col>
                    <v-col md="3">
                      <v-select
                        disabled
                        :items="states"
                        ref="state"
                        color="black"
                        label="State"
                        outlined
                        dense
                        v-model="customer.stateId"
                        item-text="state"
                        item-value="_id"
                      ></v-select>
                    </v-col>
                    <v-col md="3">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="Zip"
                        outlined
                        dense
                        v-model="customer.zip"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                 
                </v-card-title>
              </v-card>

              <v-card class="mb-1">
                <v-card-title class="titlestyle">
                  <v-row dense>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="190px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            disabled
                            v-model="customer.initialDate"
                            label="Initial"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.initialDate"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            disabled
                            v-model="customer.lastDate"
                            label="Final"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.lastDate"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="3" md="4">
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            disabled
                            v-model="customer.birthDate"
                            label="DOB"
                            outlined
                            dense
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="customer.birthDate"
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="Nature"
                        outlined
                        dense
                        v-model="customer.nature"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        disabled
                        :items="allStatus"
                        label="Status"
                        dense
                        outlined
                        v-model="customer.status"
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        ref="companyName"
                        color="black"
                        label="Agent Code"
                        outlined
                        disabled
                        dense
                        v-model="customer.agentCode"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="Means"
                        outlined
                        dense
                        v-model="customer.means"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        disabled
                        ref="companyName"
                        color="black"
                        label="Rating"
                        outlined
                        dense
                        v-model="customer.rating"
                      ></v-text-field>
                    </v-col>

                    <v-col md="12">
                      <v-textarea
                        disabled
                        ref="Comments"
                        color="black"
                        label="Comments"
                        dense
                        auto-grow
                        outlined
                        v-model="customer.description"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                  <!-- <v-layout wrap justify-center>
                    <v-flex xs12 md12 text-center>
                      <v-btn
                        dark
                        color="primary"
                        depressed
                        class="text-capitalize"
                        @click="validate()"
                        :ripple="false"
                      >
                        Submit
                      </v-btn>
                    </v-flex>
                  </v-layout> -->
                </v-card-title>
              </v-card>
              <v-card class="mb-4" v-if="notes.length>0">
                <v-card-title class="titlestyle">
                  <v-row class="ml-1" dense style="width: 100% !important">
                    <span style="font-size: 14px"><b>Notes</b></span>
                  </v-row>

                  <v-row dense>
                    <v-col md="2">
                      <v-row dense>
                        <v-col md="12">
                          <v-text-field
                            
                            ref="Agent"
                            color="black"
                            label="Agent"
                            dense
                            disabled
                            outlined
                            v-model="agentCode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col md="10">
                      <v-row dense v-for="(item, i) in notes" :key="i">
                        <v-col md="12">
                          <v-text-field
                            ref="Notes"
                            color="black"
                            label="Note"
                            disabled
                            dense
                            outlined
                            v-model="item.note"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <v-navigation-drawer
      style="position: fixed; padding-top: 4%"
      right
      width="55"
    >
      <v-list nav dense>
        
        <v-list-item link title="Pdf">
          <v-list-item-icon>
             <a
              target="blank"
              :href="
                'http://13.127.171.21/pdf/generate/Prospect_Details/?prospectId=' + $route.params.id
                 
              "
              class="downlink"
            >
              <v-icon color="primary">mdi-file-pdf</v-icon></a
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
        </v-list-item>
         <v-list-item link title="Batch Print">
          <v-list-item-icon>
            <v-icon color="primary" @click="emailDialog = true"
              >mdi-gmail</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title>Shared with me</v-list-item-title>
          <v-dialog v-model="emailDialog" max-width="800">
            <v-card>
              <v-card-title class="headline grey lighten-2">
                ABB Labels
              </v-card-title>

              <v-card-text>
                <form ref="bthPrint" class="pt-4">
                  <v-text-field
                    v-model="sendfrom"
                    label="Send From"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="sendto"
                    label="Send To"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="subject"
                    label="Subject"
                    dense
                    outlined
                    required
                  ></v-text-field>
                  <v-textarea outlined dense v-model="message" label="Message">
                  </v-textarea>
                </form>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="error" @click="emailDialog = false">
                  Cancel
                </v-btn>
                <v-btn color="primary" class="mr-4" @click="sendMail">
                  Send
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list-item>
        <v-list-item
          link
          :to="'/editProspect/' + $route.params.id"
          title="Edit"
        >
          <v-icon color="primary" dark> mdi-pencil </v-icon>

          <v-list-item-title>Starred</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
//import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      valid: true,
      companyRules: [
        (v) => !!v || "Company Name is required",
        (v) =>
          (v && v.length <= 50) ||
          "Company Name must be less than 50 characters",
      ],
      numberrules: [
        // (v) => !isNaN(parseFloat(v)) || "Number is Expected",
        (v) => v >= 0 || "Number is Expected",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      customer: {
        id: null,
        companyName: null,
        contactPerson: null,
        email: null,
        nature: null,
        address: null,
        city: null,
        stateId: null,
        countryId: null,
        zip: null,
        phone: null,
        fax: null,
        shipAddress: null,
        shipCity: null,
        shipStateId: null,
        shipCountryId: null,
        shipZip: null,
        birthDate: null,
        initialDate: new Date().toISOString().substr(0, 10),
        lastDate: null,
        termId: null,
        age: null,
        agentCode: null,
        ytd: null,
        means: null,
        rating: null,
        accountNo: null,
        status: null,
        description: null,
      },
      notes: [
        {
          note: null,
        },
      ],
      emailDialog:false,
      sendfrom: null,
      sendto: null,
      sendTo:[],
      message: null,
      subject: null,
      agentCode: "",
      showsnackbar: false,
      msg: null,
      ServerError: false,
      validateInput: null,
      timeout: 5000,
      appLoading: false,
      msg1: null,
      userData: [],
      allStatus: [],
      terms: [],
      states: [],
      menu1: false,
      menu3: false,
      menu2: false,
    };
  },

  beforeMount() {
    this.getCustomer();
    this.stateList();
    
    this.statusList();
  },
  methods: {
    nextPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/prospect/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          prospectid: this.$route.params.id,
          type: "previous",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Prospect/" + response.data.data._id);
            this.getCustomer();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    prevPage() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/prospect/previous/next",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          prospectid: this.$route.params.id,
          type: "next",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$router.push("/Prospect/" + response.data.data._id);
            this.getCustomer();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.editCustomer();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    getCustomer() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/prospect/view/" + this.$route.params.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.customer = response.data.data;
            var agCode = '';
            var agUcode = '';
             if(response.data.data.status == 'active')
            this.customer.status = 'Active'
            if(response.data.data.status == 'inactive')
            this.customer.status = 'Inactive'
            if(response.data.data.status == 'deleted')
            this.customer.status = 'Deleted'
            if(response.data.data.created_by)
              agCode = response.data.data.created_by.agentCode
            if(response.data.data.updated_by)
              agUcode = response.data.data.updated_by.agentCode
            this.customer.agentCode = agCode+'/'+agUcode;
            this.sendto = this.customer.email;
            this.subject = "ABB Label - Prospect Information for " + this.customer.companyName;
            if (this.customer.notes) 
            this.notes = this.customer.notes;
            
                this.customer.initialDate = this.ChangeFormateDate(
                  response.data.data.initialDate
                );
            this.customer.lastDate = this.ChangeFormateDate(
              response.data.data.lastDate
            );
            this.customer.birthDate = this.ChangeFormateDate(
              response.data.data.birthDate
            );
            this.getProfile();

          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getProfile() {
      // this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
           if(response.data.data.email)
           this.sendfrom = response.data.data.email;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    sendMail() {
     this.emailDialog = false;
   
     this.sendTo = (this.sendto).split(",");
      axios({
        method: "POST",
        url: "/send/email",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // status:"active",
          emailType: 'customer',
          id:this.$route.params.id,
          emailTo: this.sendTo,
          emailFrom:this.sendfrom,
          subject:this.subject,
          message:this.message,
        },
      })
        .then((response) => {
          this.appLoading = false;
          console.log(response.data.msg);
          this.message = '';
         
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
     
    stateList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/states",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.states = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    
    statusList() {
      // this.appLoading = true;
      axios({
        method: "GET",
        url: "/prospect/status",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.allStatus = response.data.data;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
  },
};
</script>

<style >
.v-text-field--outlined.v-input--dense .v-label {
  top: 8px !important;
  font-size: 11px !important;
  font-weight: bold;
  color: grey !important;
}

.v-text-field input {
  color: grey !important;
}
.v-text-field__slot textarea {
  color: grey !important;
}
.v-card__title {
  line-height: 1rem !important;
}
.titlestyle {
  padding-bottom: 0px !important;
}
a {
  text-decoration: none !important;
}
</style>