import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import store from '@/store'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/Login",
      name: "Login",
      component: () => import('@/views/pages/Login'),
      meta: { requiresAuth: false }

    },
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/layouts/Layout'),
      meta: { requiresAuth: true },
      children: [
        // Components
        {
          name: 'Customers',
          path: 'customers',
          component: () => import('@/views/Customers/Customers'),
        },
        {
          name: 'Customer',
          path: 'Customer/:id',
          component: () => import('@/views/Customers/Customer'),
        },
        {
          name: 'addCustomer',
          path: 'addCustomer',
          component: () => import('@/views/Customers/addCustomer'),
        },

        {
          name: 'editCustomer',
          path: 'editCustomer/:id',
          component: () => import('@/views/Customers/editCustomer'),
        },
        {
          name: 'Prospects',
          path: 'Prospects',
          component: () => import('@/views/Prospects/Prospects'),
        },
        {
          name: 'Prospect',
          path: 'Prospect/:id',
          component: () => import('@/views/Prospects/Prospect'),
        },
        {
          name: 'addProspect',
          path: 'addProspect',
          component: () => import('@/views/Prospects/addProspect'),
        },

        {
          name: 'editProspect',
          path: 'editProspect/:id',
          component: () => import('@/views/Prospects/editProspect'),
        },
        {
          name: 'Vendors',
          path: 'Vendors',
          component: () => import('@/views/Vendors/Vendors'),
        },
        {
          name: 'Vendor',
          path: 'Vendor/:id',
          component: () => import('@/views/Vendors/Vendor'),
        },
        {
          name: 'addVendor',
          path: 'addVendor',
          component: () => import('@/views/Vendors/addVendor'),
        },

        {
          name: 'editVendor',
          path: 'editVendor/:id',
          component: () => import('@/views/Vendors/editVendor'),
        },
        {
          name: 'Quotes',
          path: 'Quotes',
          component: () => import('@/views/Quotes/Quotes'),
        },
        {
          name: 'Quote',
          path: 'Quote/:id',
          component: () => import('@/views/Quotes/Quote'),
        },
        {
          name: 'addQuote',
          path: 'addQuote',
          component: () => import('@/views/Quotes/addQuote'),
        },

        {
          name: 'editQuote',
          path: 'editQuote/:id',
          component: () => import('@/views/Quotes/editQuote'),
        },
        // {
        //   path: '/Map/:id',
        //   props: true,
        //   meta: { label: 'Incident Map' },
        //   name: 'IncidentMap',
        //   component: () => import('@/views/Incidents/map')
        // },

        {
          name: 'Employee',
          path: 'Employee',
          component: () => import('@/views/Employees/Employees'),
        },
        {
          name: 'addEmployee',
          path: 'addEmployee',
          component: () => import('@/views/Employees/addEmployees'),
        },
        {
          name: 'Orders',
          path: 'Orders',
          component: () => import('@/views/Orders/Orders'),
        },
        {
          name: 'openOrders',
          path: 'openOrders',
          component: () => import('@/views/Orders/openOrders'),
        },
        {
          name: 'Order',
          path: 'Order/:id',
          component: () => import('@/views/Orders/Order'),
        },
        {
          name: 'editOrder',
          path: 'editOrder/:id',
          component: () => import('@/views/Orders/editOrder'),
        },
        {
          name: 'addOrder',
          path: 'addOrder',
          component: () => import('@/views/Orders/addOrder'),
        },
        {
          name: 'Invoices',
          path: 'Invoices',
          component: () => import('@/views/Invoice/Invoices'),
        },
        {
          name: 'addInvoice',
          path: 'addInvoice',
          props: true,
          component: () => import('@/views/Invoice/addInvoice'),
        },
        {
          name: 'Invoice',
          path: 'Invoice/:id',
          component: () => import('@/views/Invoice/Invoice'),
        },
        {
          name: 'editInvoice',
          path: 'editInvoice/:id',
          component: () => import('@/views/Invoice/editInvoice'),
        },
        {
          name: 'statements',
          path: 'statements',
          component: () => import('@/views/Invoice/statements'),
        },
        {
          name: 'agingReports',
          path: 'agingReports',
          component: () => import('@/views/Invoice/agingReports'),
        },
        {
          name: 'agingReportdetail',
          path: 'agingReportdetail/:id',
          component: () => import('@/views/Invoice/agingReportdetail'),
        },
        {
          name: 'Changepassword',
          path: 'Changepassword',
          component: () => import('@/views/Settings/changePassword'),
        },
        {
          name: 'Alerts',
          path: 'pages/alerts',
          component: () => import('@/views/pages/Alerts'),
        },

        {
          name: 'Profile',
          path: 'pages/profile',
          component: () => import('@/views/pages/Profile'),
        },

        {
          name: 'Icons',
          path: 'pages/icons',
          component: () => import('@/views/pages/Icons'),
        },

        {
          name: 'TableSimple',
          path: 'pages/tables-simple',
          component: () => import('@/views/pages/TableSimple'),
        },

        {
          name: 'Dashboard',
          path: 'dashboard',
          component: () => import('@/views/dashboard/BasicDashboard'),
        },

      ]
    },

  ],
})

router.beforeEach((to, from, next) => {
  if (localStorage.getItem('token')) {
    var newURL = ''
    if (localStorage.getItem('role') == 'employee')
      newURL = "/authenticate/user";

    else
      newURL = "authenticate/admin";
    axios({
      method: "POST",
      url: newURL,
      headers: {
        "token": localStorage.getItem("token")
      }
    })
      .then(response => {
        if (!response.data.status) {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          store.commit("logoutUser");
          next({
            name: 'Login'
          })
          return
        }
      })
  }
  if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
    next({
      name: 'Login'
    })
    return
  }
  if (to.path === '/Login' && store.state.isLoggedIn) {
    next({
      path: '/'
    })
    return
  }
  next()
})

export default router