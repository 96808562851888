<template>
  <v-layout wrap justify-center>
    <v-flex px-2 xs12 sm12 md12 lg12 xl12 text-center>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12 text-center class="pt-2">
          <!-- <v-card>
             <vue-element-loading :active="graphLoading" :is-full-screen="false" />
            <v-card-text class="pa-5">
              <div class="d-sm-flex align-center">
                <div>
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular"
                  >
                    Sales Overview
                  </h3>
                </div>
                <div class="ml-auto">
                  <div class="d-flex align-center">
                    <div class="d-flex align-center px-2">
                      <v-flex md4>
                      <v-select
                            solo
                            label="Year"
                            v-model="year"
                            dense
                            required
                            :items="years()"
                          ></v-select>
                     </v-flex>
                    </div>
                   
                  </div>
                </div>
              </div>
              <div class="mt-9">
              
                <apexchart
                  ref="realtimeChart"
                  v-if="graphSuccess"
                  type="bar"
                  height="400"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card> -->
          <v-card>
            <vue-element-loading
              :active="graphLoading"
              :is-full-screen="false"
            />
            <v-card-text class="pa-5">
              <v-layout>
                <v-flex md6 class="text-left">
                  <h3
                    class="title blue-grey--text text--darken-2 font-weight-regular pt-2"
                  >
                    Sales Overview
                  </h3>
                </v-flex>
              
                <v-flex md4 class="text-right"> </v-flex>
                 
                      <v-flex md2>
                        <v-select
                          solo
                          label="Month"
                          v-model="month"
                          dense
                          required
                          :items="months"
                          item-value="value"
                          item-text="text"
                        ></v-select>
                      </v-flex>
                      <v-flex md2>
                        <v-select
                          solo
                          label="Year"
                          v-model="year"
                          dense
                          required
                          :items="years()"
                        ></v-select>
                      </v-flex>
                  
                
              </v-layout>
              <div class="mt-9">
                <apexchart
                  ref="realtimeChart"
                  v-if="graphSuccess"
                  type="bar"
                  height="400"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <!-- <v-flex xs3 md3 pt-2 pr-2>
          <v-card>
            <v-card-text class="pa-5">
              <h2 class="font-weight-medium success--text text-center">
                Total Customers
              </h2>

              <v-divider class="mt-4"></v-divider>

              <v-row class="my-1" align="center">
                <span class="mx-4 info--text"
                  ><v-icon color="primary" dark>
                    mdi-account-multiple
                  </v-icon></span
                >

                <v-divider vertical></v-divider>

                <span class="mx-4 success--text"
                  >Count : {{ ttlCustomers }}</span
                >

                <v-spacer></v-spacer>
              </v-row>

              <v-divider class="mb-4"></v-divider>
              <v-btn class="mt-8" text to="/Customers" rounded color="primary">
                View all
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex> -->
        <v-flex xs3 md3 pt-2 pr-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-text class="p-3 clearfix">
              <v-list-item link to="/Customers" style="padding:0!important;">
                <v-list-item-icon>
                  <v-icon large color="primary" class="py-0"
                    >mdi-account-multiple</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Total Customers</v-list-item-title>
                  <v-list-item-subtitle class="info--text"
                    ><b>Count :{{ ttlCustomers }}</b></v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="info--text"
                    ><br
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs3 md3 pt-2 pr-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-text class="p-3 clearfix">
              <v-list-item link to="/Orders" style="padding:0!important;">
                <v-list-item-icon>
                  <v-icon large color="success" class="py-0"
                    >mdi-book-open</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Total Orders</v-list-item-title>
                  <v-list-item-subtitle class="info--text"
                    ><b>Count :{{ ttlOrders }}</b></v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="info--text"
                    ><br
                  /></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-flex>

        <!-- <v-flex xs3 md3 pt-2 pr-2>
          <v-card>
            <v-card-text class="pa-5">
              <h2 class="font-weight-medium success--text text-center">
                Total Orders
               
              </h2>

              <v-divider class="mt-4"></v-divider>

              <v-row class="my-1" align="center">
                <span class="mx-4 info--text">
                  <v-icon color="primary" dark> mdi-book-open </v-icon></span
                >

                <v-divider vertical></v-divider>

                <span class="mx-4 success--text">Count : {{ ttlOrders }}</span>

                <v-spacer></v-spacer>

              </v-row>
              <v-divider class="mb-4"></v-divider>

              <v-btn class="mt-8" text to="/Orders" rounded color="primary">
                View all
              </v-btn>
            </v-card-text>
          </v-card>
        </v-flex> -->

        <v-flex xs3 md3 pt-2 pr-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-text class="p-3 clearfix">
              <v-list-item link to="/Invoices" style="padding:0!important;">
                <v-list-item-icon>
                  <v-icon large color="error" class="py-0"
                    >mdi-file-document</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Total Invoices</v-list-item-title>
                  <v-list-item-subtitle class="info--text"
                    ><b>Count :{{ ttlInvoices }}</b></v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="info--text"
                    ><b
                      >Total Balance :${{ balAmount }}</b
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs3 md3 pt-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-text class="p-3 clearfix">
              <v-list-item link to="/openOrders" style="padding:0!important;">
                <v-list-item-icon>
                  <v-icon large color="warning" class="py-0"
                    >mdi-timer-sand</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Open Orders</v-list-item-title>
                  <v-list-item-subtitle class="info--text"
                    ><b>Count :{{ openCount }}</b></v-list-item-subtitle
                  >
                  <v-list-item-subtitle class="info--text"
                    ><b
                      > Open Orders :${{ openAmount }}</b
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex md4 pr-2 v-if="todaysCustomers.length > 0">
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Today's Customers </v-card-title>
            <v-data-table
              dense
              :headers="customerheaders"
              :items="todaysCustomers"
              hide-default-footer
              @click:row="customerrowClicked"
              class="row-pointer tablewid pb-12 tableValue"
            >
              <template v-slot:item.email="{ item }">
                <span v-if="item.contactPerson">{{ item.contactPerson }}</span
                ><br />
                <span style="color: darkblue" v-if="item.email">{{
                  item.email.slice(0, 37)
                }}</span>
              </template>
              <template v-slot:item.nature="{ item }">
                <span v-if="item.nature">
                  {{ item.nature }}
                </span>
              </template>
              <template v-slot:item.address="{ item }">
                <span v-if="item.address">
                  {{ item.address.slice(0, 16) }} </span
                ><br />
                <span v-if="item.city">{{ item.city }}</span>
                <span v-if="item.zip"> , {{ item.zip }}</span>
              </template>
              <template v-slot:item.phone="{ item }">
                <span style="color: darkblue" v-if="item.phone">{{
                  item.phone
                }}</span>
                <!-- <br />
                    <span style="color: darkblue" v-if="item.fax">{{
                      item.fax
                    }}</span> -->
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.created_by"
                  >{{ item.created_by.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
                <br />
                <span v-if="item.number">{{ item.number }}</span>
              </template>
              <template v-slot:item.nature="{ item }">
                <span v-if="item.nature">{{ item.nature.slice(0, 5) }}</span>
              </template>
              <template v-slot:item.rating="{ item }">
                <span>{{ item.statusValue }}/{{ item.rating }}</span>
              </template>
              <template v-slot:item.initialDate="{ item }">
                <span v-if="item.initialDate">{{
                  ChangeFormateDate(item.initialDate)
                }}</span
                >
                <!-- <br />
                <span v-if="item.lastDate">{{
                  ChangeFormateDate(item.lastDate)
                }}</span> -->
              </template>
              
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Customers" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex md4 pr-2 v-if="todaysOrders.length > 0">
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Today's Orders </v-card-title>
            <v-data-table
            dense
              :headers="orderheaders"
              :items="todaysOrders"
              hide-default-footer
              class="row-pointer pb-12 tableValue"
              @click:row="orderrowClicked"
            >
              <!-- @click:row="rowClicked" :items-per-page="itemsPerPage" -->
              <template v-slot:item.orderID="{ item }">
                <v-layout wrap justify-center @click="rowClicked(item)">
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.orderID" style="color: darkblue">{{
                      item.orderID
                    }}</span>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:item.orderDate="{ item }">
                <span v-if="item.orderDate">{{
                  ChangeFormateDate(item.orderDate)
                }}</span>
              </template>
              <template v-slot:item.shippingDate="{ item }">
                <span v-if="item.shippingDate">{{
                  ChangeFormateDate(item.shippingDate)
                }}</span>
              </template>
              <template v-slot:item.productUnitPrice="{ item }">
                <span v-if="item.productUnitPrice"
                  >${{ item.productUnitPrice }}</span
                >
                <span v-else>$0</span>
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.customerId"
                  >{{ item.customerId.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
              </template>
              <template v-slot:item.billToCompany="{ item }">
                <span
                  v-if="item.billToCompany"
                  v-html="item.billToCompany.slice(0, 15)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.productDescription="{ item }">
                <span
                  v-if="item.productDescription"
                  v-html="item.productDescription.slice(0, 30)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>

              <template v-slot:item.totalAmount="{ item }">
                <span v-if="item.totalAmount">${{ item.totalAmount }}</span>
                <span v-else>$0</span>
              </template>
              
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Orders" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex md4 v-if="todaysInvoices.length > 0">
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Today's Invoices </v-card-title>
            <v-data-table
              dense
              :headers="invoiceheaders"
              :items="todaysInvoices"
              hide-default-footer
              @click:row="invoicerowClicked"
              class="row-pointer pb-12 tableValue"
            >
              <!-- @click:row="rowClicked" -->
              <template v-slot:item.invoiceDate="{ item }">
                <span>{{ ChangeFormateDate(item.invoiceDate) }}</span>
              </template>
              <template v-slot:item.invoiceStatus="{ item }">
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) <= 60
                  "
                  style="color: #fa8072"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 60 &&
                    dateDifference(item.invoiceDate) <= 90
                  "
                  style="color: #dc143c"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 90 &&
                    dateDifference(item.invoiceDate) <= 120
                  "
                  style="color: #ff0000"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 120
                  "
                  style="color: #800000"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >

                <span
                  v-else-if="item.invoiceStatus == 'PAID'"
                  style="color: #008000"
                >
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-else-if="item.invoiceStatus == 'PARTIAL'"
                  style="color: #fdee00"
                >
                  {{ item.invoiceStatus }}</span
                >
              </template>
              <template v-slot:item.invoiceID="{ item }">
                <v-layout wrap justify-center>
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.invoiceID" style="color: darkblue">{{
                      item.invoiceID
                    }}</span>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:item.orderId="{ item }">
                <v-layout wrap justify-center @click.stop="orderClicked(item)">
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.orderId" style="color: darkblue">{{
                      item.orderId.orderID
                    }}</span>
                  </v-flex>
                </v-layout>
                <!-- <span style="color: darkblue" v-if="item.orderId">{{
                      item.orderId.orderID
                    }}</span> -->
                <!-- <router-link v-if="item.orderId" tag='span' :to="'/Order/'+item.orderId._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderId.orderID }}</v-btn>
                    </router-link> -->
              </template>
              <template v-slot:item.billToCompany="{ item }">
                <span
                  v-if="item.billToCompany"
                  v-html="item.billToCompany.slice(0, 15)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.productDescription="{ item }">
                <span
                  v-if="item.productDescription"
                  v-html="item.productDescription.slice(0, 30)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.created_by"
                  >{{ item.created_by.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
              </template>
              <template v-slot:item.totalAmount="{ item }">
                <span>${{ item.totalAmount }}</span>
              </template>
              <template v-slot:item.balance="{ item }">
                <span>${{ item.balance }}</span>
              </template>
              <!-- <template v-slot:cell(invoiceStatus)="data">
                    <span v-if="data.item.invoiceStatus=='paid'" style="color:'green'">{{
                      data.item.status
                    }}</span>
                    <span v-else>-</span>
                  </template> -->
              
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Invoices" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>

        <v-flex md4 pr-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Recent Customers </v-card-title>
            <v-data-table
              dense
              :headers="customerheaders"
              :items="recentCustomers"
              hide-default-footer
              @click:row="customerrowClicked"
              class="row-pointer tablewid tableValue"
            >
              <template v-slot:item.email="{ item }">
                <span v-if="item.contactPerson">{{ item.contactPerson }}</span
                ><br />
                <span style="color: darkblue" v-if="item.email">{{
                  item.email.slice(0, 37)
                }}</span>
              </template>
              <template v-slot:item.nature="{ item }">
                <span v-if="item.nature">
                  {{ item.nature }}
                </span>
              </template>
              <template v-slot:item.address="{ item }">
                <span v-if="item.address">
                  {{ item.address.slice(0, 16) }} </span
                ><br />
                <span v-if="item.city">{{ item.city }}</span>
                <span v-if="item.zip"> , {{ item.zip }}</span>
              </template>
              <template v-slot:item.phone="{ item }">
                <span style="color: darkblue" v-if="item.phone">{{
                  item.phone
                }}</span>
                <!-- <br />
                    <span style="color: darkblue" v-if="item.fax">{{
                      item.fax
                    }}</span> -->
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.created_by"
                  >{{ item.created_by.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
                <br />
                <span v-if="item.number">{{ item.number }}</span>
              </template>
              <template v-slot:item.nature="{ item }">
                <span v-if="item.nature">{{ item.nature.slice(0, 5) }}</span>
              </template>
              <template v-slot:item.rating="{ item }">
                <span>{{ item.statusValue }}/{{ item.rating }}</span>
              </template>
              <template v-slot:item.initialDate="{ item }">
                <span v-if="item.initialDate">{{
                  ChangeFormateDate(item.initialDate)
                }}</span
                >
                <!-- <br />
                <span v-if="item.lastDate">{{
                  ChangeFormateDate(item.lastDate)
                }}</span> -->
              </template>
              
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Customers" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex md4 pr-2>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Recent Orders </v-card-title>
            <v-data-table
              dense
              :headers="orderheaders"
              :items="recentOrders"
              hide-default-footer
              class="row-pointer tableValue"
              @click:row="orderrowClicked"
            >
              <!-- @click:row="rowClicked" :items-per-page="itemsPerPage" -->
              <template v-slot:item.orderID="{ item }">
                <v-layout wrap justify-center @click="rowClicked(item)">
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.orderID" style="color: darkblue">{{
                      item.orderID
                    }}</span>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:item.orderDate="{ item }">
                <span v-if="item.orderDate">{{
                  ChangeFormateDate(item.orderDate)
                }}</span>
              </template>
              <template v-slot:item.shippingDate="{ item }">
                <span v-if="item.shippingDate">{{
                  ChangeFormateDate(item.shippingDate)
                }}</span>
              </template>
              <template v-slot:item.productUnitPrice="{ item }">
                <span v-if="item.productUnitPrice"
                  >${{ item.productUnitPrice }}</span
                >
                <span v-else>$0</span>
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.customerId"
                  >{{ item.customerId.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
              </template>
              <template v-slot:item.billToCompany="{ item }">
                <span
                  v-if="item.billToCompany"
                  v-html="item.billToCompany.slice(0, 15)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.productDescription="{ item }">
                <span
                  v-if="item.productDescription"
                  v-html="item.productDescription.slice(0, 30)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>

              <template v-slot:item.totalAmount="{ item }">
                <span v-if="item.totalAmount">${{ item.totalAmount }}</span>
                <span v-else>$0</span>
              </template>
            
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Orders" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex md4>
          <v-card class="mb-2">
            <vue-element-loading
              :active="recentLoading"
              :is-full-screen="false"
            />
            <v-card-title> Recent Invoices </v-card-title>
            <v-data-table
              dense
              :headers="invoiceheaders"
              :items="recentInvoices"
              hide-default-footer
              @click:row="invoicerowClicked"
              class="row-pointer tableValue"
            >
              <!-- @click:row="rowClicked" -->
              <template v-slot:item.invoiceDate="{ item }">
                <span>{{ ChangeFormateDate(item.invoiceDate) }}</span>
              </template>
              <template v-slot:item.invoiceStatus="{ item }">
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) <= 60
                  "
                  style="color: #fa8072"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 60 &&
                    dateDifference(item.invoiceDate) <= 90
                  "
                  style="color: #dc143c"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 90 &&
                    dateDifference(item.invoiceDate) <= 120
                  "
                  style="color: #ff0000"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-if="
                    item.invoiceStatus == 'OPEN' &&
                    dateDifference(item.invoiceDate) > 120
                  "
                  style="color: #800000"
                  >{{ dateDifference(item.invoiceDate) }} -
                  {{ item.invoiceStatus }}</span
                >

                <span
                  v-else-if="item.invoiceStatus == 'PAID'"
                  style="color: #008000"
                >
                  {{ item.invoiceStatus }}</span
                >
                <span
                  v-else-if="item.invoiceStatus == 'PARTIAL'"
                  style="color: #fdee00"
                >
                  {{ item.invoiceStatus }}</span
                >
              </template>
              <template v-slot:item.invoiceID="{ item }">
                <v-layout wrap justify-center>
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.invoiceID" style="color: darkblue">{{
                      item.invoiceID
                    }}</span>
                  </v-flex>
                </v-layout>
              </template>
              <template v-slot:item.orderId="{ item }">
                <v-layout wrap justify-center @click.stop="orderClicked(item)">
                  <v-flex xs12 text-align-center>
                    <!-- <router-link tag='span' :to="'/Order/'+item._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderID }}</v-btn>
                    </router-link> -->
                    <span v-if="item.orderId" style="color: darkblue">{{
                      item.orderId.orderID
                    }}</span>
                  </v-flex>
                </v-layout>
                <!-- <span style="color: darkblue" v-if="item.orderId">{{
                      item.orderId.orderID
                    }}</span> -->
                <!-- <router-link v-if="item.orderId" tag='span' :to="'/Order/'+item.orderId._id">
                            <v-btn small text color="blue" class="text-capitalize">{{ item.orderId.orderID }}</v-btn>
                    </router-link> -->
              </template>
              <template v-slot:item.billToCompany="{ item }">
                <span
                  v-if="item.billToCompany"
                  v-html="item.billToCompany.slice(0, 15)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.productDescription="{ item }">
                <span
                  v-if="item.productDescription"
                  v-html="item.productDescription.slice(0, 30)"
                >
                  <!-- {{ item.productDescription.slice(0, 40) }} -->..
                </span>
              </template>
              <template v-slot:item.agentCode="{ item }">
                <span v-if="item.created_by"
                  >{{ item.created_by.agentCode
                  }}<span v-if="item.updated_by"
                    >/{{ item.updated_by.agentCode }}</span
                  ></span
                >
              </template>
              <template v-slot:item.totalAmount="{ item }">
                <span>${{ item.totalAmount }}</span>
              </template>
              <template v-slot:item.balance="{ item }">
                <span>${{ item.balance }}</span>
              </template>
              <!-- <template v-slot:cell(invoiceStatus)="data">
                    <span v-if="data.item.invoiceStatus=='paid'" style="color:'green'">{{
                      data.item.status
                    }}</span>
                    <span v-else>-</span>
                  </template> -->
              
            </v-data-table>
            <v-card-actions>
              <v-btn class="mt-8" text to="/Invoices" rounded color="primary"
                >View All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-flex>

   
  </v-layout>
</template>


<script>
import axios from "axios";
// import ApexCharts from 'apexcharts'

export default {
  name: "BasicDashboard",

  data: () => ({
    graphSuccess: false,
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    // series: [
    //   {
    //     name: "Customers",

    //     data: [],
    //   },
    //   {
    //     name: "Orders",

    //     data: [],
    //   },
    //   {
    //     name: "Invoices",

    //     data: [],
    //   },
    // ],
    // chartOptions: {
    //   chart: {
    //     type: "bar",
    //     height: 350,

    //   },
    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: "55%",
    //       endingShape: "rounded",
    //     },
    //   },
    //   dataLabels: {
    //     enabled: false,
    //   },
    //   stroke: {
    //     show: true,
    //     width: 2,
    //     colors: ["transparent"],
    //   },
    //   xaxis: {
    //     categories: [],

    //   },
    //   yaxis: {
    //     title: {
    //       text: "count",
    //     },
    //   },
    //   fill: {
    //     opacity: 1,
    //   },
    //   tooltip: {
    //     y: {
    //       formatter: function (val) {

    //         return val;
    //       },
    //     },
    //   },
    // },
    series: [
      {
        name: "Orders",

        data: [],
      },
      {
        name: "Invoices",

        data: [],
      },
    ],
    chartOptions: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "count",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            // return "$ " + val + " thousands";
            return val;
          },
        },
      },
    },

    customerheaders: [
      {
        text: "Cutomer#",
        align: "start",
        value: "customerID",

        sortable: false,
      },
      {
        text: "Company",
        align: "start",

        value: "companyName",
        sortable: false,
      },

      {
        text: "I/L Date",
        value: "initialDate",
        align: "start",

        sortable: false,
      },
    ],
    orderheaders: [
      {
        text: "Ord#",
        align: "start",
        value: "orderID",

        sortable: false,
      },
      { text: "Date", value: "orderDate", sortable: false },

      {
        text: "Company",
        value: "billToCompany",

        sortable: false,
      },

      {
        text: "Total",
        value: "totalAmount",

        align: "end",
        sortable: false,
      },
      // { text: "Agent/User", value: "agentCode", width: "10%", sortable: false },
    ],
    invoiceheaders: [
      {
        text: "Inv.#",
        align: "start",
        value: "invoiceID",

        sortable: false,
      },

      { text: "Date", value: "invoiceDate", sortable: true },
      {
        text: "Company",
        value: "billToCompany",

        sortable: false,
      },

      {
        text: "Amt",
        value: "totalAmount",

        align: "end",
        sortable: false,
      },
    ],
    recentCustomers: [],
    recentOrders: [],
    recentInvoices: [],
    todaysCustomers: [],
    todaysOrders: [],
    todaysInvoices: [],

    tasks: [
      {
        done: false,
        text: "Check emails of Mr.Shah",
      },
      {
        done: false,
        text: "Give salary to employee",
      },
    ],
    task: null,
    selected: [2],
    items: [
      {
        action: "15 min",
        headline: "Brunch this weekend?",
        title: "Ali Connors",
        subtitle:
          "I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
      },
      {
        action: "2 hr",
        headline: "Summer BBQ",
        title: "me, Scrott, Jennifer",
        subtitle: "Wish I could come, but I'm out of town this weekend.",
      },
      {
        action: "6 hr",
        headline: "Oui oui",
        title: "Sandra Adams",
        subtitle: "Do you have Paris recommendations? Have you ever been?",
      },
      {
        action: "12 hr",
        headline: "Birthday gift",
        title: "Trevor Hansen",
        subtitle:
          "Have any ideas about what we should get Heidi for her birthday?",
      },
      {
        action: "18hr",
        headline: "Recipe to try",
        title: "Britta Holt",
        subtitle:
          "We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
      },
    ],
    openAmount: null,
    openCount: null,
    balAmount: null,
    dashData: null,
    ttlOrders: null,
    ttlInvoices: null,
    ttlCustomers: null,
    graphLoading: false,
    recentLoading: false,
    months: [
      { text: "January", value: 0 },
      { text: "February", value: 1 },
      { text: "March", value: 2 },
      { text: "April", value: 3 },
      { text: "May", value: 4 },
      { text: "June", value: 5 },
      { text: "July", value: 6 },
      { text: "August", value: 7 },
      { text: "September", value: 8 },
      { text: "October", value: 9 },
      { text: "November", value: 10 },
      { text: "December", value: 11 },
    ],
  }),
  computed: {
    completedTasks() {
      return this.tasks.filter((task) => task.done).length;
    },
    progress() {
      return (this.completedTasks / this.tasks.length) * 100;
    },
    remainingTasks() {
      return this.tasks.length - this.completedTasks;
    },
  },
  watch: {
    year() {
      this.graphSuccess = false;
      // this.series[0].data = [];
      this.series[0].data = [];
      this.series[1].data = [];
      this.chartOptions.xaxis.categories = [];
      this.dashboardgraphData();
    },
    month() {
      this.graphSuccess = false;
      // this.series[0].data = [];
      this.series[0].data = [];
      this.series[1].data = [];
      this.chartOptions.xaxis.categories = [];
      this.dashboardgraphData();
    },
  },

  beforeMount() {
    // this.getDashboard();
    this.dashboardData();
    this.dashboardgraphData();
    this.years();
  },
  methods: {
    years() {
      const year = new Date().getFullYear();

      return Array.from(
        { length: year - 2000 },
        (value, index) => 2001 + index
      );
    },

    getDashboard() {
      axios({
        method: "GET",
        url: "/dashboard/data",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.dashData = response.data.data;

            this.ttlOrders = this.dashData.orders;
            this.ttlInvoices = this.dashData.invoices;
            this.ttlCustomers = this.dashData.customers;
            this.openOrderCount();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    openOrderCount() {
      axios({
        method: "POST",
        url: "/openOrders/count/amount",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.openCount = response.data.count;
            this.openAmount = response.data.amount;
            this.INVbalanceAmt();
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    INVbalanceAmt() {
      axios({
        method: "POST",
        url: "/invoices/balance",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          //this.appLoading = false;
          if (response.data.status) {
            this.balAmount = response.data.balance;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dashboardgraphData() {
      this.graphLoading = true;

      axios({
        method: "GET",
        url: "/dashboard/weekly/graph",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          year: this.year,
          month: this.month + 1,
        },
      })
        .then((response) => {
          this.graphLoading = false;

          if (response.data.status) {
            this.graphData = response.data.graph;
            for (var i = 0; i < this.graphData.length; i++) {
              if (this.graphData.length > 0) {
                this.chartOptions.xaxis.categories.push(
                  this.graphData[i].weekofMonth
                );
                // this.series[0].data.push(this.graphData[i].customer);
                this.series[0].data.push(this.graphData[i].order);
                this.series[1].data.push(this.graphData[i].invoice);
              }
            }

            //  this.updateSeriesLine();
            this.graphSuccess = true;

            // console.log('hjm',this.chartOptions.xaxis.categories)
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    //   dashboardgraphData() {
    //     this.graphLoading = true;

    //   axios({
    //     method: "GET",
    //     url: "/dashboard/stats",

    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params:{
    //       year:this.year,
    //     }
    //   })
    //     .then((response) => {
    //       this.graphLoading = false;

    //       if (response.data.status) {
    //         this.graphData = response.data.graph;
    //         for (var i = 0; i < this.graphData.length; i++) {
    //           if (this.graphData.length > 0) {
    //             this.chartOptions.xaxis.categories.push(this.graphData[i].month);
    //             // this.series[0].data.push(this.graphData[i].customer);
    //             this.series[0].data.push(this.graphData[i].order);
    //             this.series[1].data.push(this.graphData[i].invoice);
    //           }

    //         }

    //          this.updateSeriesLine();
    //         this.graphSuccess = true;

    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    updateSeriesLine() {
      var data = {};
      data.series = this.series;
      data.chartOptions = this.chartOptions;
      console.log(data);
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
    dashboardData() {
      this.graphLoading = true;
      this.recentLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/stats",

        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          year: this.year,
        },
      })
        .then((response) => {
          this.graphLoading = false;
          this.recentLoading = false;
          if (response.data.status) {
            this.graphData = response.data.graph;
            // for (var i = 0; i < this.graphData.length; i++) {
            //   if (this.graphData.length > 0) {
            //     this.chartOptions.xaxis.categories.push(this.graphData[i].month);
            //     this.series[0].data.push(this.graphData[i].customer);
            //     this.series[1].data.push(this.graphData[i].order);
            //     this.series[2].data.push(this.graphData[i].invoice);
            //   }
            // }
            if (response.data.recent) {
              this.recentCustomers = response.data.recent.customers;
              this.recentOrders = response.data.recent.orders;
              this.recentInvoices = response.data.recent.invoices;
            }
            if (response.data.today) {
              this.todaysCustomers = response.data.today.customers;
              this.todaysOrders = response.data.today.orders;
              this.todaysInvoices = response.data.today.invoices;
            }

            // this.graphSuccess = false;
            this.ttlOrders = response.data.ordersCount;
            this.ttlInvoices = response.data.invoicesCount;
            this.ttlCustomers = response.data.customersCount;
            this.openOrderCount();
            // console.log('hjm',this.chartOptions.xaxis.categories)
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dateDifference(date) {
      // var date1 = new Date().toISOString().substr(0, 10)
      // const _MS_PER_DAY = 1000 * 60 * 60 * 24;

      // Discard the time and time-zone information.

      const utc1 = new Date().toISOString().substr(0, 10);

      if (date) {
        var newdateFormat = date.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
      }

      const utc2 = year + "-" + month + "-" + day;

      return Math.floor(
        (this.parseDate(utc1) - this.parseDate(utc2)) / (1000 * 60 * 60 * 24)
      );
    },

    parseDate(str) {
      var mdy = str.split("-");
      return new Date(mdy[0], mdy[1] - 1, mdy[2]);
    },
    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }

        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];

        return month + "/" + day + "/" + year;
      }
    },
    customerrowClicked(item) {
      this.$router.push("/Customer/" + item._id);
    },
    orderrowClicked(item) {
      // console.log(item)
      this.$router.push("/Order/" + item._id);
    },
    invoicerowClicked(item) {
      this.$router.push("/Invoice/" + item._id);
    },
    orderClicked(item) {
      if (item.orderId) this.$router.push("/Order/" + item.orderId._id);
    },
  },
};
</script>
<style >
tbody tr:nth-of-type(odd) {
  background-color: rgba(28, 133, 219, 0.05);
}
.row-pointer>.v-data-table__wrapper>table>tbody>tr :hover {  
cursor: pointer;
}
.v-data-table td,
.v-data-table th {
  padding: 0 10px !important;
}
.v-data-table td {
  font-size: .7em;
}
</style>

