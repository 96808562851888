import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './plugins/base'

import VueElementLoading from 'vue-element-loading'
Vue.component('VueElementLoading', VueElementLoading)
import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
 
Vue.component('apexchart', VueApexCharts)

import Notifications from 'vue-notification'
Vue.use(Notifications)

window.axios = require('axios')
import axios from 'axios'


Vue.prototype.baseURL = 'http://13.233.107.20' // URL for File retriving Globaly initialized
axios.defaults.baseURL = 'http://13.233.107.20' //Development Server URL
axios.defaults.timeout = 60000;



Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')
